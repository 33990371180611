@import '../../../bower_components/k-sass-core/src/sass/k-sass-core';

@import "_include/body";
@import "_include/_header/header001";
@import "_include/_footer/footer001";
@import "_include/_rotate/rotate";
@import "_include/_breadcrumb/breadcrumb";
/////////////////////////////////
.product-dragon {
	.title-group {
		font-family: Tw-Cen-MT-Bold;
		@include fs(40rem);
		line-height: 1;
		padding: 25px 0;
		/////1760 x 846 - 92%
		@media(max-width:1760px) {
			@include fs(36.8rem);
			padding: calc(30px * 0.92) 0; }
		/////1600 x 769 - 83%
		@media(max-width:1600px) {
			@include fs(33.2rem);
			padding: calc(30px * 0.83) 0; }
		/////1440 x 692 - 75%
		@media(max-width:1440px) {
			@include fs(30rem);
			padding: calc(30px * 0.75) 0; }
		/////1366 x 656 - 72%
		@media(max-width:1366px) {
			@include fs(28.8rem);
			padding: calc(30px * 0.72) 0; }
		/////1280 x 615 - 67%
		@media(max-width:1280px) {
			@include fs(26.8rem);
			padding: calc(30px * 0.67) 0; }
		/////51%
		@include media-breakpoint-down(lg);
		/////28%
		@include media-breakpoint-down(sm);
		/////17%
		@include media-breakpoint-down(xs); }
	.group-thuml {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		/////40%
		@include media-breakpoint-down(md) {
			padding: 0 15px; }
		.bd-image {
			@include pos(rel,null);
			z-index: 1; }
		.mobi-ct {
			display: none !important;
			@include media-breakpoint-down(sm) {
				display: flex !important; } }
		.pc-ct {
			@include media-breakpoint-down(sm) {
				display: none; } }
		.thuml-image {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			z-index: 3;
			@include pos(rel,null);

			.item {
				float: left;
				width: calc(100% / 3);
				text-align: center;
				padding: 30px;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {
					padding: calc(30px * 0.92); }
				/////1600 x 769 - 83%
				@media(max-width:1600px) {
					padding: calc(30px * 0.83); }
				/////1440 x 692 - 75%
				@media(max-width:1440px) {
					padding: calc(30px * 0.75); }
				/////1366 x 656 - 72%
				@media(max-width:1366px) {
					padding: calc(30px * 0.72); }
				/////1280 x 615 - 67%
				@media(max-width:1280px) {
					padding: calc(30px * 0.67); }
				/////51%
				@include media-breakpoint-down(lg) {

					padding: calc(30px * 0.51); }
				/////28%
				@include media-breakpoint-down(sm) {
					width: calc(100% / 2.1); }
				/////17%
				@include media-breakpoint-down(xs); } }
		.line-thuml {
			@include pos(abs,50% null null 0);
			@include transform(translate(0,-50%));
			width: 100%;
			z-index: 2;
			/////1760 x 846 - 92%
			@media(max-width:1760px) {}
			/////1600 x 769 - 83%
			@media(max-width:1600px) {}
			/////1440 x 692 - 75%
			@media(max-width:1440px) {}
			/////1366 x 656 - 72%
			@media(max-width:1366px) {}
			/////1280 x 615 - 67%
			@media(max-width:1280px) {}
			/////51%
			@include media-breakpoint-down(lg);
			/////40%
			@include media-breakpoint-down(md) {
 }				// display: none
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs);
			span {
				@include pos(rel,null);
				height: 22px;
				display: block;
				&::before {
					content: '';
					width: 100%;
					height: 2px;
					background-color: $main; }
				&::before {
					@include pos(abs,50% null null 0);
					@include transform(translate(0,-50%)); } } } }
	.group-banner {
		.banner {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			.item {
				float: left;
				width: calc(100% / 3);
				/////1760 x 846 - 92%
				@media(max-width:1760px) {}
				/////1600 x 769 - 83%
				@media(max-width:1600px) {}
				/////1440 x 692 - 75%
				@media(max-width:1440px) {}
				/////1366 x 656 - 72%
				@media(max-width:1366px) {}
				/////1280 x 615 - 67%
				@media(max-width:1280px) {}
				/////51%
				@include media-breakpoint-down(lg);
				/////40%
				@include media-breakpoint-down(md) {
					width: calc(100% / 2.3);
					padding: 10px 15px; }
				/////28%
				@include media-breakpoint-down(sm);
				/////17%
				@include media-breakpoint-down(xs);
				img {
					width: 100%; } } } }
	.detail-product {
		/////1760 x 846 - 92%
		@media(max-width:1760px) {}
		/////1600 x 769 - 83%
		@media(max-width:1600px) {}
		/////1440 x 692 - 75%
		@media(max-width:1440px) {}
		/////1366 x 656 - 72%
		@media(max-width:1366px) {}
		/////1280 x 615 - 67%
		@media(max-width:1280px) {}
		/////51%
		@include media-breakpoint-down(lg);
		/////40%
		@include media-breakpoint-down(md) {
			padding: 0 15px; }
		/////28%
		@include media-breakpoint-down(sm);
		/////17%
		@include media-breakpoint-down(xs); }
	.group-farm {
		/////40%
		@include media-breakpoint-down(md) {
			padding: 0 15px; }
		.farm {
			padding: 40px 72px;
			// margin: 100px 0
			border: 5px solid #cd8a70;
			@include fs(30rem);
			/////1760 x 846 - 92%
			@media(max-width:1760px) {
				@include fs(27.6rem);
				padding: calc(40px * 0.92) calc(72px * 0.92); }
			/////1600 x 769 - 83%
			@media(max-width:1600px) {
				@include fs(24.9rem);
				padding: calc(40px * 0.83) calc(72px * 0.83); }
			/////1440 x 692 - 75%
			@media(max-width:1440px) {
				@include fs(22.5rem);
				padding: calc(40px * 0.75) calc(72px * 0.75); }
			/////1366 x 656 - 72%
			@media(max-width:1366px) {
				@include fs(21.6rem);
				padding: calc(40px * 0.72) calc(72px * 0.72); }
			/////1280 x 615 - 67%
			@media(max-width:1280px) {
				@include fs(20.1rem);
				padding: calc(40px * 0.67) calc(72px * 0.67); }
			/////51%
			@include media-breakpoint-down(lg) {
				@include fs(15.3rem);
				padding: calc(40px * 0.51) calc(72px * 0.51); }
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs);
			.info-farm {
				font-family: Tw-Cen-MT-Bold; }
			.des-farm {
				font-family: Tw-Cen-MT-Italic;
				margin-top: 40px;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {
					margin-top: calc(40px * 0.92); }
				/////1600 x 769 - 83%
				@media(max-width:1600px) {
					margin-top: calc(40px * 0.83); }
				/////1440 x 692 - 75%
				@media(max-width:1440px) {
					margin-top: calc(40px * 0.75); }
				/////1366 x 656 - 72%
				@media(max-width:1366px) {
					margin-top: calc(40px * 0.72); }
				/////1280 x 615 - 67%
				@media(max-width:1280px) {
					margin-top: calc(40px * 0.67); }
				/////51%
				@include media-breakpoint-down(lg);
				/////28%
				@include media-breakpoint-down(sm);
				/////17%
				@include media-breakpoint-down(xs); } }
		.tt-group {
			padding: 15px 30px;
			text-align: justify;
			@include fs(30rem);
			/////1760 x 846 - 92%
			@media(max-width:1760px) {
				@include fs(27.6rem);
				padding: calc(15px * 0.92) calc(30px * 0.92); }
			/////1600 x 769 - 83%
			@media(max-width:1600px) {
				@include fs(24.9rem);
				padding: calc(15px * 0.83) calc(30px * 0.83); }
			/////1440 x 692 - 75%
			@media(max-width:1440px) {
				@include fs(22.5rem);
				padding: calc(15px * 0.75) calc(30px * 0.75); }
			/////1366 x 656 - 72%
			@media(max-width:1366px) {
				@include fs(21.6rem);
				padding: calc(15px * 0.72) calc(30px * 0.72); }
			/////1280 x 615 - 67%
			@media(max-width:1280px) {
				@include fs(20.1rem);
				padding: calc(15px * 0.67) calc(30px * 0.67); }
			/////51%
			@include media-breakpoint-down(lg) {
				@include fs(15.3rem);
				padding: calc(15px * 0.51) 0; }
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs); } }
	.group-story {
		display: flex;
		align-items: center;
		/////1760 x 846 - 92%
		@media(max-width:1760px) {}
		/////1600 x 769 - 83%
		@media(max-width:1600px) {}
		/////1440 x 692 - 75%
		@media(max-width:1440px) {}
		/////1366 x 656 - 72%
		@media(max-width:1366px) {}
		/////1280 x 615 - 67%
		@media(max-width:1280px) {}
		/////51%
		@include media-breakpoint-down(lg);
		/////40%
		@include media-breakpoint-down(md) {
			padding: 0 15px; }
		/////28%
		@include media-breakpoint-down(sm);
		/////17%
		@include media-breakpoint-down(xs);
		.ct-group {
			text-align: center;
			.link {
				font-style: italic;
				color: $hmain;
				text-decoration: underline; }
			img {
				margin-top: 5px;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {}
				/////1600 x 769 - 83%
				@media(max-width:1600px) {
					width: 50%; }
				/////1440 x 692 - 75%
				@media(max-width:1440px) {}
				/////1366 x 656 - 72%
				@media(max-width:1366px) {}
				/////1280 x 615 - 67%
				@media(max-width:1280px) {}
				/////51%
				@include media-breakpoint-down(lg);
				/////40%
				@include media-breakpoint-down(md) {
					width: 100%; }
				/////28%
				@include media-breakpoint-down(sm);
				/////17%
				@include media-breakpoint-down(xs); } } } }
